import React, { useEffect } from 'react';
import {
  fetchLasUserTransactionDocument,
  generateInteractionToken,
  requestLoanClosure,
} from '../../../../utils/apiService';
import interactionIntents from '../../../../constants/interactionIntents';
import { handleErrorMessage, logSharedResponse } from '../../../../utils';
import ButtonGroup from 'antd/lib/button/button-group';
import { Button, Col, Collapse, Divider, InputNumber, Row, Tag } from 'antd';
import Typography from 'antd/lib/typography/Typography';
import SummaryTable from './SummaryTable';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import DataTable, { columnsType } from './DataTable';
import { downloadPDF } from './utils';
import CloseLoan from './CloseLoan';

const paymentType = {
  PRINCIPAL_REPAYMENT: 'principal',
  BOUNCED_INTEREST_PAYMENT: 'bounced_interest',
  SHORTFALL_PAYMENT: 'shortfall',
  LOAN_CLOSURE_REPAYMENT: 'loan_closure',
};

async function runRepayInteraction(userId, opaqueId, lid, type) {
  try {
    const { data } = await generateInteractionToken({
      intent: interactionIntents.PAYMENT,
      config: {
        type: type,
        lid: lid,
        lender: 'bajaj_finserv',
        opaqueId: opaqueId,
        userId: userId,
      },
    });
    const response = await window.las.pay({
      interactionToken: data.interactionToken,
    });
    logSharedResponse('Las sdk response', response);
  } catch (e) {
    handleErrorMessage(e);
  }
}
async function runWithdrawInteraction(userId, opaqueId, lid, amount) {
  try {
    const { data } = await generateInteractionToken({
      intent: interactionIntents.WITHDRAW,
      config: {
        amount: amount.toString(),
        lender: 'bajaj_finserv',
        opaqueId: opaqueId,
        userId: userId,
        lid: lid,
      },
    });
    const response = await window.las.withdraw({
      interactionToken: data.interactionToken,
    });
    logSharedResponse('Las sdk response', response);
  } catch (e) {
    handleErrorMessage(e);
  }
}

async function getTransactionDoc(userId, lid, document) {
  const data = await fetchLasUserTransactionDocument(userId, lid, document);
  downloadPDF(data, `loan_${lid}_${document}.pdf`);
}

const ServicingActions = ({ serial, userId, opaqueId, loan }) => {
  const { lid } = loan;
  const [withdrawalAmount, setWithdrawalAmount] = React.useState(null);
  const loanSummary = loan.summary.loanSummary;
  const allTransactions = loan.summary.allTransactions;
  const mutualFundsPledged = loan.summary.mutualFundsPledged;
  const warnings = (loanSummary
    ? Object.keys(loanSummary.warnings).map(warning =>
        loanSummary.warnings[warning].isActive ? (
          <Tag color="red">{warning}</Tag>
        ) : null,
      )
    : []
  ).filter(w => w !== null);

  return (
    <>
      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        Serial : {serial}
        <br />
        Loan Summary for LID: {lid}
      </Typography>
      {warnings.length ? (
        <>
          <Divider children={'Warnings'}></Divider>
          {warnings}
        </>
      ) : null}

      <Divider children={'Summary'}></Divider>
      <Collapse>
        <CollapsePanel header="Loan summary" key="smry">
          <SummaryTable summary={loanSummary?.summary} />
        </CollapsePanel>
        <CollapsePanel header="All transactions" key="txns">
          <DataTable
            colType={columnsType.recent}
            details={allTransactions}
            showPagination={true}
          />
        </CollapsePanel>
        <CollapsePanel header="Pledged MFs Statement" key="pledgedMfs">
          <DataTable
            details={mutualFundsPledged}
            colType={columnsType.mfs}
            showPagination={false}
          />
        </CollapsePanel>
        <CollapsePanel header="Recent transactions (last 3)" key="recentTxns">
          <DataTable
            colType={columnsType.recent}
            details={loanSummary?.transaction.recent}
            showPagination={false}
          />
        </CollapsePanel>
        <CollapsePanel header="Download Docs" key="Docs">
          <Button
            onClick={() => {
              getTransactionDoc(userId, lid, 'transactionhistory');
            }}
          >
            Transactions PDF{' '}
          </Button>
          <Button
            onClick={() => {
              getTransactionDoc(userId, lid, 'statement');
            }}
          >
            MF HOLDINGS PDF{' '}
          </Button>
        </CollapsePanel>
        <CollapsePanel header="Upcoming transactions " key="upcomingTxns">
          <DataTable
            colType={columnsType.upcoming}
            showPagination={false}
            details={[loanSummary?.transaction.upcoming]}
          />
        </CollapsePanel>
      </Collapse>
      {loanSummary?.flow.repayCash && (
        <>
          <Divider children={'Repayments'}></Divider>
          <Row gutter={[48, 24]}>
            <Col span={12}>
              <Button
                onClick={() =>
                  runRepayInteraction(
                    userId,
                    opaqueId,
                    lid,
                    paymentType.PRINCIPAL_REPAYMENT,
                  )
                }
                block
              >
                Principal
              </Button>
            </Col>
            <Col span={12}>
              <Button
                onClick={() =>
                  runRepayInteraction(
                    userId,
                    opaqueId,
                    lid,
                    paymentType.BOUNCED_INTEREST_PAYMENT,
                  )
                }
                block
              >
                Bounced interest
              </Button>
            </Col>
            <Col span={12}>
              <Button
                onClick={() =>
                  runRepayInteraction(
                    userId,
                    opaqueId,
                    lid,
                    paymentType.SHORTFALL_PAYMENT,
                  )
                }
                block
              >
                Shortfall
              </Button>
            </Col>
            <Col span={12}>
              <Button
                onClick={() =>
                  runRepayInteraction(
                    userId,
                    opaqueId,
                    lid,
                    paymentType.LOAN_CLOSURE_REPAYMENT,
                  )
                }
                block
              >
                Loan Closure
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Collapse>
        <CollapsePanel header="Close Loan" key="closeLoan">
          <CloseLoan
            triggerLoanClose={() => {
              requestLoanClosure(userId, lid);
            }}
          />
        </CollapsePanel>
      </Collapse>
      {loanSummary?.flow.withdrawCash && (
        <>
          <Divider children={'Withdrawals'}></Divider>
          <ButtonGroup style={{ width: '100%' }}>
            <InputNumber
              placeholder="Amount to withdraw"
              style={{ width: '100%' }}
              onChange={e => {
                if (e && typeof e === 'number' && e > 0) setWithdrawalAmount(e);
              }}
              value={withdrawalAmount}
            />

            <Button
              disabled={!withdrawalAmount}
              type="primary"
              onClick={() =>
                runWithdrawInteraction(userId, opaqueId, lid, withdrawalAmount)
              }
              block
            >
              Withdraw
            </Button>
          </ButtonGroup>
        </>
      )}
    </>
  );
};

export default ServicingActions;
