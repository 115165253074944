/* global BASE_URL */
import axios from 'axios';

const httpHandler = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const httpBlobHandler = axios.create({
  baseURL: BASE_URL,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json',
  },
});

export function searchAPI(text) {
  return httpHandler
    .get('/search', {
      params: {
        text,
      },
    })
    .then(res => res.data);
}

export function searchMfAPI(text) {
  return httpHandler
    .get('/search/mf', {
      params: {
        text,
      },
    })
    .then(res => res.data);
}

export function googleAuth(code) {
  return httpHandler
    .get('/auth/login', {
      params: {
        code,
      },
    })
    .then(res => res.data);
}
export function googleAuthLogout(code) {
  return httpHandler.get('/auth/logout').then(res => res.data);
}

export function checkAuth() {
  return httpHandler
    .get('/auth/authenticate', {
      withCredentials: true,
    })
    .then(res => res.data);
}

export function loginAPI(id) {
  return httpHandler
    .post('/user/login', {
      id,
    })
    .then(res => res.data);
}

export function connectAPI(id, smallcaseAuthToken) {
  return httpHandler.post('/user/connect', {
    id,
    smallcaseAuthToken,
  });
}

export function transactionAPI(id, intent, config, notes, version, pointV2) {
  // config name varies according to intent
  let configObjName = 'orderConfig';
  if (intent === 'SUBSCRIPTION') configObjName = 'config';
  if (intent === 'HOLDINGS_IMPORT' || intent === 'MF_HOLDINGS_IMPORT')
    configObjName = 'assetConfig';
  let responseConfig;
  if (intent === 'MF_HOLDINGS_IMPORT' && config) {
    responseConfig = config.responseConfig;
    delete config.responseConfig;
  }
  return httpHandler
    .post(
      '/transaction/new',
      {
        id,
        intent,
        ...(config && { [configObjName]: config }),
        ...(responseConfig && { responseConfig }),
        ...(notes && { notes }),
        ...(version && { version }),
      },
      {
        headers: {
          'x-gwapi-version': pointV2 ? 'v2' : 'v1',
        },
      },
    )
    .then(res => res.data);
}

export function holdingsAPI(id, includeMf, version) {
  return httpHandler
    .get('/holdings/fetch', {
      params: {
        id,
        ...(includeMf && { mfHoldings: true }),
        ...(version && { version }),
      },
    })
    .then(res => res.data);
}

export function postbackAPI(transactionId) {
  return httpHandler
    .get('/transaction/response', {
      params: {
        transactionId,
      },
    })
    .then(res => res.data);
}

export function mfAnalyticsAPI(transactionId) {
  return httpHandler
    .get('/transaction/mf/analytics', {
      params: {
        transactionId,
      },
    })
    .then(res => res.data.data.data);
}

export function getMfSummaryAPI(mfId) {
  return httpHandler
    .get('/transaction/mf/summary', {
      params: {
        mfId: mfId,
      },
    })
    .then(res => res.data.results);
}

export function historicalOrdersAPI(transactionId, batchId, userId) {
  return httpHandler
    .get('/orders/fetch', {
      params: {
        transactionId,
        batchId,
        userId,
      },
    })
    .then(res => res.data);
}

export function checkLeadStatusAPI(leadId) {
  return httpHandler
    .get('/lead', {
      params: {
        leadId,
      },
    })
    .then(res => res.data);
}

export function createNewSubscriptionAPI(scid, userId) {
  return httpHandler
    .post('/subscription/new', {
      scid,
      userId,
    })
    .then(res => res.data);
}

export function cancelSubscriptionAPI(scid, userId) {
  return httpHandler
    .post('/subscription/cancel', {
      scid,
      userId,
    })
    .then(res => res.data);
}

export function getAllSubscriptionAPI(userId) {
  return httpHandler
    .get('/subscription/fetch', {
      params: {
        userId,
      },
    })
    .then(res => res.data);
}

export function getPendingActions(id) {
  return httpHandler
    .get('/user/investment', {
      params: {
        id,
      },
    })
    .then(res => res.data);
}

export function verifyAccessToken(token) {
  return httpHandler
    .post('/user/verifyToken', {
      token,
    })
    .then(res => res.data);
}

export function generateInteractionToken({intent, config, offers, offerBanner}) {
  return httpHandler
    .post('/las/interaction', { intent, config, offers, offerBanner})
    .then(res => res.data);
}

export function createLasUser(id, additionalData) {
  return httpHandler
    .post('/las/user', { id, ...additionalData })
    .then(res => res.data);
}

export function fetchLasUser(id) {
  return httpHandler.get('/las/user', { params: { id } }).then(res => res.data);
}

export function fetchLoans(id) {
  return httpHandler
    .get('/las/loans-status', { params: { id } })
    .then(res => res.data);
}

export function fetchLasUserTransactionDocument(id, loanId, document) {
  return httpBlobHandler
    .post('/las/transaction-document', { id, loanId, document })
    .then(res => res.data);
}

export function requestLoanClosure(lasUserId, loanId) {
  return httpHandler
    .get('/las/requestLoanClosure', { params: { id: lasUserId, lid: loanId } })
    .then(res => res.data);
}
