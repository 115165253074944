/* global GAPI_CLIENT_ID */
import React, { Component } from 'react';
import Root from './scenes/Root';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default class App extends Component {
  render() {
    return (
      <GoogleOAuthProvider clientId={GAPI_CLIENT_ID}>
        <Root />
      </GoogleOAuthProvider>
    );
  }
}
