/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
// import { googleAuth } from '../services/api';
import { Button } from 'antd';
import { googleAuth, googleAuthLogout } from '../../../../utils/apiService';
import { GoogleCircleFilled } from '@ant-design/icons';
import { useAuthStore } from '../../../../store/auth';

const GoogleLogin = () => {
  const { isUserAuthenticated, setUserAuthenticated } = useAuthStore();
  const responseGoogle = async authResult => {
    try {
      if (authResult['code']) {
        await googleAuth(authResult.code);
        setUserAuthenticated(true);
      } else {
        throw new Error(authResult);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const googleLogout = async () => {
    try {
      await googleAuthLogout();
      setUserAuthenticated(false);
    } catch (e) {
      console.log(e);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
    flow: 'auth-code',
    select_account: true,
  });
  if (!isUserAuthenticated)
    return (
      <Button
        className=" ios-click full-width "
        style={{
          marginTop: '10px',
        }}
        onClick={googleLogin}
      >
        <GoogleCircleFilled /> Login
      </Button>
    );

  return (
    <Button
      className=" ios-click full-width "
      style={{
        marginTop: '10px',
      }}
      onClick={googleLogout}
    >
      <GoogleCircleFilled />
      Logout
    </Button>
  );
};

export default GoogleLogin;
