import { Button, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import GoogleLogin from '../Login/GoogleLogin';
import { useAuthStore } from '../../../../store/auth';

const CONNECTED = 'CONNECTED';
const UNCONNECTED = 'UNCONNECTED';

const SideBar = ({
  shouldShowInSMT,
  shouldShowInSST,
  status,
  changeModalState,
  login,
  pathname,
  updateStatus,
  logoutFromBroker,
  Sider,
}) => {
  const { setUserAuthenticated, isUserAuthenticated } = useAuthStore();
  return (
    <div>
      <Sider
        collapsedWidth="0"
        breakpoint="lg"
        style={{ zIndex: 100, minHeight: '100vh' }}
      >
        <div className="logo">
          <img
            src="https://assets.smallcase.com/gateway/smartInvesting/smart-invest.svg"
            alt=""
            className="nav-link ios-click"
          />
        </div>
        <Menu
          theme="dark"
          mode="vertical"
          selectedKeys={[pathname]}
          style={{ lineHeight: '64px' }}
        >
          {shouldShowInSMT && (
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/discover'}>
                {'Discover'}
              </Link>
            </Menu.Item>
          )}
          {shouldShowInSST && (
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/create'}>
                {'Create'}
              </Link>
            </Menu.Item>
          )}
          {shouldShowInSST && (
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/mf-transaction'}>
                {'MF Transactions'}
              </Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <Link className="nav-link ios-click" to={'/holdings'}>
              {'Holdings / Funds'}
            </Link>
          </Menu.Item>
          {shouldShowInSMT && status === 'CONNECTED' && (
            <Menu.Item>
              <Link className="nav-link" to={'/investments'}>
                {'Investment'}
              </Link>
            </Menu.Item>
          )}
          {shouldShowInSMT && status === 'CONNECTED' && (
            <Menu.Item>
              <Link className="nav-link" to={'/subscriptions'}>
                {'Subscriptions'}
              </Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <Link className="nav-link ios-click" to={'/postback'}>
              {'Postback'}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link className="nav-link ios-click" to={'/historicalOrders'}>
              {'Historical Orders'}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link className="nav-link ios-click" to={'/signup'}>
              {'Signup'}
            </Link>
          </Menu.Item>
          {shouldShowInSMT && (
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/smallplug'}>
                {'Smallplug'}
              </Link>
            </Menu.Item>
          )}
          {isUserAuthenticated && (
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/las'}>
                Loans Against MFs
              </Link>
            </Menu.Item>
          )}
          {isUserAuthenticated && (
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/pl'}>
                Personal Loans
              </Link>
            </Menu.Item>
          )}
          {isUserAuthenticated && (
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/mutualfunds'}>
                MF holdings import
              </Link>
            </Menu.Item>
          )}
        </Menu>

        <Button
          className="mt8 ios-click full-width"
          onClick={() => {
            if (status === UNCONNECTED) {
              return changeModalState(true);
            }
            // Logout if the user is already connected
            login(updateStatus, null);
          }}
        >
          {status === UNCONNECTED && 'Connect'}{' '}
          {status === CONNECTED && 'Logout'}
        </Button>

        {status === CONNECTED && (
          <Button
            size="small"
            type="link"
            className="ios-click fw"
            onClick={() => {
              logoutFromBroker();
            }}
          >
            smallcase Platform Logout
          </Button>
        )}

        <GoogleLogin
          setUserAuthenticated={setUserAuthenticated}
          isUserAuthenticated={isUserAuthenticated}
        />
      </Sider>
    </div>
  );
};

export default SideBar;
