import React, { useEffect } from 'react';
import LoginPage from '../Login/LoginPage';
import { checkAuth } from '../../../../utils/apiService';
import { useAuthStore } from '../../../../store/auth';
import { Route } from 'react-router-dom';

const checkGoogleAuth = async () => {
  try {
    await checkAuth();
    return true;
  } catch (e) {
    return false;
  }
};

const ProtectedRoute = ({ path, children }) => {
  const { isUserAuthenticated, setUserAuthenticated } = useAuthStore();

  const checkAuth = async () => {
    if (await checkGoogleAuth()) setUserAuthenticated(true);
    else setUserAuthenticated(false);
  };
  useEffect(() => {
    checkAuth();
  }, []);
  return (
    <Route
      path={path}
      render={() => {
        if (!isUserAuthenticated) {
          return (
            <LoginPage
              setUserAuthenticated={setUserAuthenticated}
              isUserAuthenticated={isUserAuthenticated}
            />
          );
        }
        return children;
      }}
    />
  );
};

export default ProtectedRoute;
