import React from 'react';
import { Result } from 'antd';
import GoogleLogin from './GoogleLogin';

const LoginPage = props => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f2f5',
      }}
    >
      <Result
        status="warning"
        title="You have been logged out"
        subTitle="Please log in again to continue using the application."
        extra={
          <GoogleLogin
            setUserAuthenticated={props.setUserAuthenticated}
            isUserAuthenticated={props.isUserAuthenticated}
          />
        }
      />
    </div>
  );
};

export default LoginPage;
