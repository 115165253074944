import React, { useState, useEffect } from 'react';
import { Avatar, Button, Card, Col, Divider, Icon, Row } from 'antd';
import {
  generateInteractionToken,
  createLasUser,
  fetchLasUser,
  fetchLoans,
} from '../../../../utils/apiService';
import interactionIntents from '../../../../constants/interactionIntents';
import { handleErrorMessage, logSharedResponse } from '../../../../utils';
import { setCookie, getCookie } from '../../../../utils/cookieService';
import NewUserForm from './NewUserForm';
import OldUserForm from './OldUserForm';
import CreateOfferForm from './CreateOffersForm';
import ServicingActions from './ServicingActions';

const LAS_OPAQUEID_COOKIE = 'lasOpaqueId';
const DASHBOARD_DIV_ID = 'dashboard-wrapper';

export const creditProductType = {
  LAMF: 'lamf',
  PL: 'pl',
};

/**
 * Start the application.
 *
 * @param {string} userId - The user ID.
 * @param {string} opaqueId - The opaque ID.
 * @param {"LAMF" | "PL"} productType - The productType (default: 'LAMF').
 * @param {"LOAN_APPLICATION" | "LOAN_APPLICATION:RENEWAL"} intent - The productType (default: 'LAMF').
 * @returns {Promise<void>} - A promise that resolves when the application starts.
 */
async function startApplication({
  userId,
  opaqueId,
  productType = creditProductType.LAMF,
  intent = interactionIntents.LOAN_APPLICATION,
  offers,
}) {
  try {
    const { data } = await generateInteractionToken({
      intent,
      config: {
        userId,
        opaqueId,
        lender: productType === creditProductType.PL ? 'abfl' : 'bajaj_finserv',
        productType,
      },
      offers,
    });
    const response = await window.las.apply({
      interactionToken: data.interactionToken,
    });
    logSharedResponse('Las sdk response', response);
  } catch (e) {
    handleErrorMessage(e);
  }
}

async function openDashboard(
  userId,
  opaqueId,
  loans,
  productType,
  offerBanner,
) {
  try {
    const { data } = await generateInteractionToken(
      {
        intent: interactionIntents.SERVICE,
        config: {
          userId,
          opaqueId,
          lender:
            productType === creditProductType.LAMF ? 'bajaj_finserv' : 'abfl',
          loans: loans.map(loan => ({ lid: loan.lid })),
        },
        offerBanner,
      },
    );
    const { task, close } = window.las.service({
      interactionToken: data.interactionToken,
      target: DASHBOARD_DIV_ID,
    });
    task
      .then(response => logSharedResponse('Las sdk response', response))
      .catch(handleErrorMessage);
    return close;
  } catch (e) {
    handleErrorMessage(e);
  }
}

async function createUser(id, additionalData) {
  try {
    const { data } = await createLasUser(id, additionalData);
    setCookie(LAS_OPAQUEID_COOKIE, data.opaqueId, 7);
    return data;
  } catch (e) {
    handleErrorMessage(e);
  }
}

async function fetchUser(id) {
  try {
    const { data } = await fetchLasUser(id);
    setCookie(LAS_OPAQUEID_COOKIE, data.opaqueId, 7);
    return data;
  } catch (e) {
    handleErrorMessage(e);
  }
}

async function fetchLoanStatus(id) {
  try {
    const { data } = await fetchLoans(id);
    return data;
  } catch (e) {
    handleErrorMessage(e);
  }
}

const Las = ({ productType }) => {
  const [isLoading, setLoading] = useState(false);
  const [lasUserId, setLasUserId] = useState('');
  const [opaqueId, setOpaqueId] = useState('');
  const [loans, setLoans] = useState([]);
  const [dashboardClose, setDashboardClose] = useState();
  const [isFindUserVisible, setFindUserVisible] = useState(false);
  const [offers, setOffers] = useState(null);
  const [dashboardOffer, setDashboardOffer] = useState(null);

  const handleFormSubmit = async ({
    userId,
    pan,
    dob,
    contacts,
    mfHoldings,
    bankAccounts,
    isLienMarkingMocked,
    authContact,
  }) => {
    setLoading(true);
    const response = await createUser(userId, {
      pan,
      dob,
      contacts,
      mfHoldings,
      bankAccounts,
      authContact,
      data: { isLienMarkingMocked },
      productType: productType === creditProductType.LAMF ? 'lamf' : 'pl',
      lender: productType === creditProductType.LAMF ? 'bajaj_finserv' : 'abfl',
    });
    if (response) {
      setLasUserId(response.lasUserId);
      setOpaqueId(response.opaqueId);
    }
    setLoading(false);
  };

  const handleFindOldUser = async ({ userId }) => {
    setLoading(true);
    const response = await fetchUser(userId);
    if (response) {
      setLasUserId(response.lasUserId);
      setOpaqueId(response.opaqueId);
    }
    setLoading(false);
  };

  const handleFetchLoans = async () => {
    setLoading(true);
    const response = await fetchLoanStatus(lasUserId);
    const status = response ? response.journeyStatus : null;
    if (status) {
      setLoans(status.loans);
    }
    setLoading(false);
  };

  useEffect(() => {
    const userId = getCookie(LAS_OPAQUEID_COOKIE);
    if (!userId) return;
    handleFindOldUser({ userId: userId });
  }, []);

  const handleApplyLoan = async (
    intent = interactionIntents.LOAN_APPLICATION,
  ) => {
    setLoading(true);
    await startApplication({
      userId: lasUserId,
      opaqueId,
      productType,
      intent,
      offers,
    });
    setLoading(false);
  };

  const handleReset = () => {
    setCookie(LAS_OPAQUEID_COOKIE, '', 0);
    setLasUserId(null);
  };

  const applyAsGuest = async () => {
    setLoading(true);
    await startApplication({ opaqueId: 'guest', productType, offers });
    setLoading(false);
  };

  const toggleDashboard = async () => {
    if (dashboardClose) {
      dashboardClose();
      setDashboardClose(undefined);
      return;
    }
    const close = await openDashboard(
      lasUserId,
      opaqueId,
      loans,
      productType,
      dashboardOffer,
    );
    const closeWrapper = () => {
      close();
      setDashboardClose(undefined);
    };
    setDashboardClose(() => closeWrapper);
  };

  if (!lasUserId) {
    return (
      <Row>
        <Col offset={1} span={22}>
          <NewUserForm
            onSubmit={handleFormSubmit}
            isLoading={isLoading}
            extra={
              <div>
                <Button onClick={() => setFindUserVisible(true)}>
                  Find Existing user
                </Button>

                <Button onClick={() => applyAsGuest()}>
                  {productType === creditProductType.LAMF
                    ? 'Apply LAMF guest'
                    : 'Apply PL guest'}
                </Button>
              </div>
            }
          />
          <OldUserForm
            onSubmit={handleFindOldUser}
            isLoading={isLoading}
            isVisible={isFindUserVisible}
            onCancel={() => setFindUserVisible(false)}
          />
          <Divider />
          <CreateOfferForm
            onSubmit={(offers, bannerConfig) => {
              setOffers(offers);
              setDashboardOffer(bannerConfig);
            }}
          />
        </Col>
      </Row>
    );
  }
  const dashboardControl = (
    <Button
      type="primary"
      className="ml16"
      onClick={toggleDashboard}
      loading={isLoading}
    >
      {dashboardClose ? 'Close dashboard' : 'Show dashboard'}
    </Button>
  );

  return (
    <div className="container">
      <Row type="flex" justify="center" gutter={16}>
        <Col span={12} offset={2}>
          <Card
            style={{ width: 300 }}
            actions={[<Button onClick={handleReset}>Reset</Button>]}
          >
            <Card.Meta
              avatar={
                opaqueId ? <Avatar>{opaqueId}</Avatar> : <Avatar icon="user" />
              }
              title={opaqueId}
              description={`las user id: ${lasUserId}`}
            />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Card
        title="Manage your loans"
        extra={
          <>
            {loans.length ? (
              dashboardControl
            ) : (
              <Button
                type="primary"
                className="ml16"
                onClick={handleFetchLoans}
                loading={isLoading}
              >
                <Icon type="search" /> Fetch Loans Summary
              </Button>
            )}
            <Button
              type="primary"
              className="ml16"
              onClick={() => handleApplyLoan()}
              loading={isLoading}
            >
              <Icon type="form" /> Apply
            </Button>
            {productType === creditProductType.LAMF && (
              <Button
                type="primary"
                className="ml16"
                onClick={() => handleApplyLoan(interactionIntents.RENEWAL)}
                loading={isLoading}
              >
                <Icon type="reload" /> Renew
              </Button>
            )}
            {productType === creditProductType.LAMF && (
              <Button
                type="primary"
                className="ml16"
                onClick={() => handleApplyLoan(interactionIntents.TOP_UP)}
                loading={isLoading}
              >
                <Icon type="plus-circle" /> Topup
              </Button>
            )}
          </>
        }
      >
        <Row gutter={16}>
          <div id={DASHBOARD_DIV_ID} />
          {[...loans].map((loan, iter) => {
            if (loan.summary)
              return (
                <Col span={24} lg={24} key={`loan-${iter}`}>
                  <Card>
                    <ServicingActions
                      serial={iter + 1}
                      loan={loan}
                      opaqueId={opaqueId}
                      userId={lasUserId}
                    />
                  </Card>
                </Col>
              );
            return null;
          })}
        </Row>
        <Divider />
        <CreateOfferForm
          onSubmit={(offers, bannerConfig) => {
            setOffers(offers);
            setDashboardOffer(bannerConfig);
          }}
        />
      </Card>
    </div>
  );
};

export default Las;
